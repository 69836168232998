.streetmix-logo {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../images/logo_horizontal.svg");
  background-position: center center;
  background-repeat: no-repeat;
}

.streetmix-logo-horizontal {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../images/logo_horizontal.svg");
  background-position: center center;
  background-repeat: no-repeat;
}

.streetmix-wordmark {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../images/wordmark_green.svg");
  background-position: center center;
  background-repeat: no-repeat;
}

body.phone #error,
body.phone #loading {
  font-size: 2rem;
}

#svg {
  display: none;
}

#error,
#loading {
  background-color: $sky-colour;
  color: black;

  h1 {
    @include large-message-text;
  }
}

#error {
  @include blocking-shield;

  z-index: $z-09-error;

  // Errors may be displayed on old browsers without flexbox.
  // This forces vertical centering
  display: table;
}

.error-content {
  position: relative;
  text-align: center;
  line-height: 1.6;

  // Errors may be displayed on old browsers without flexbox.
  // This forces vertical centering
  display: table-cell;
  vertical-align: middle;

  a {
    color: black;
    white-space: nowrap;
  }

  button,
  a.button-like {
    margin-top: 1em;
  }

  button + button {
    margin-left: 1em;
  }

  .error-help-link {
    margin-top: 2em;
    font-size: 0.85em;

    a,
    a:visited,
    a:active {
      color: $colour-turquoise-700;
    }

    a:hover {
      color: $colour-turquoise-600;
    }
  }
}

#loading {
  @include blocking-shield;

  z-index: $z-09-loading;
  transition: opacity 120ms;
  opacity: 1;
  will-change: opacity;

  // Slightly lighter than sky colour, and closer to the blue of new colour scheme
  background-color: rgb(178, 211, 226);

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  .loading-stuck {
    position: absolute;
    bottom: 20px;
    background-color: $alert-background;
    border-bottom: $alert-border;
    padding: 1em;
    border-radius: $border-radius-medium;
    opacity: 0;
    transition: opacity 120ms, transform 150ms ease-out;
    transform: translateY(10px);
  }
}

#loading .streetmix-logo {
  top: -100px;
}

#loading .streetmix-logo,
.error-content .streetmix-logo {
  width: 80%;
  height: 100px;
  max-height: 100px;
  background-position: center center;
}

#loading .loading-spinner {
  top: -30px; // Height of spinner element; positions it closer to center

  &::before {
    border-left-color: rgba(255, 255, 255, 0.75);
    border-right-color: rgba(255, 255, 255, 0.75);
    border-bottom-color: rgba(255, 255, 255, 0.75);
  }
}

#loading noscript {
  width: 100%;
  height: 60px;
  // Position over the loading spinner
  margin-top: -80px;
  background-color: $sky-colour;
  text-align: center;
  z-index: 1;
  // Center display if flexbox is supported
  display: flex;
  justify-content: center;
  align-items: center;
}

// --------------------------------------------------------

.debug-hover-polygon {
  position: absolute;
  z-index: $z-07-debug-hover-polygon;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;

  canvas {
    position: absolute;
    left: 0;
    top: 0;
  }
}
