@import "../../styles/variables.scss";

$range-handle-color: $colour-midnight-500 !default;
$range-handle-color-hover: $colour-turquoise-500 !default;
$range-handle-color-focus: $colour-turquoise-500 !default;
$range-handle-size: 16px !default;

$range-track-color: transparent !default;
$range-track-border-color: $colour-midnight-300 !default;
$range-track-height: 6px !default;

.range-slider-item {
  position: relative;

  input {
    appearance: none;
    width: 100%;
    height: $range-track-height;
    border-radius: $border-radius-small;
    border: 1px solid $range-track-border-color;
    background: $range-track-color;
    outline: none;
    padding: 0;
    margin: 0;
    cursor: pointer;

    // Range Handle
    &::-webkit-slider-thumb {
      appearance: none;
      width: $range-handle-size;
      height: $range-handle-size;
      border-radius: 50%;
      background: $range-handle-color;
      cursor: pointer;

      &:hover {
        background: $range-handle-color-hover;
      }
    }

    &:active::-webkit-slider-thumb {
      background: $range-handle-color-hover;
    }

    &::-moz-range-thumb {
      width: $range-handle-size;
      height: $range-handle-size;
      border: 0;
      border-radius: 50%;
      background: $range-handle-color;
      cursor: pointer;

      &:hover {
        background: $range-handle-color-hover;
      }
    }

    &:active::-moz-range-thumb {
      background: $range-handle-color-hover;
    }

    // Focus state
    &:focus {
      /* Prettier cannot properly handle multi-line CSS values with comments
          See also: https://github.com/prettier/prettier/issues/7039 */
      &::-webkit-slider-thumb {
        /* prettier-ignore */
        box-shadow:
          inset 0 0 0 1px white,
          0 0 0 1px white, /* Match background color */
          0 0 0 3px $colour-emerald-400;
      }
    }

    // Firefox Overrides
    &::-moz-range-track {
      background: $range-track-color;
      border: 0;
    }

    &::-moz-focus-inner,
    &::-moz-focus-outer {
      border: 0;
    }
  }

  /* Positions the label */
  label {
    display: inline-block;
    position: relative;
    padding: 0 0.5rem; /* Breathing room around outline property */
    cursor: pointer;
  }
}
