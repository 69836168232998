@import "../../styles/variables.scss";

.print-container {
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: $z-10-print;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

@media print {
  .print-container {
    display: flex !important;
  }

  // Hide all other stuff
  body > *:not(#react-app),
  #react-app > *:not(.print-container) {
    display: none;
  }
}
