$share-menu-width: 350px;

.share-menu {
  min-width: $share-menu-width;
}

.share-via-link-container {
  width: $share-menu-width;
  padding: 0.5em 1em 0.5em 2.5em;
  border-bottom: 1px solid #cde1ea;

  [dir="rtl"] & {
    padding: 0.5em 2.5em 0.5em 1em;
  }
}

.share-via-link-form {
  display: flex;
  flex-direction: row;
  margin-top: 0.5em;

  input {
    flex-grow: 1;
  }

  button {
    padding: 4px 10px;
  }
}

.share-via-link {
  line-height: 18px;
  margin-right: 0.5em;
  outline: none;

  [dir="rtl"] & {
    margin-right: auto;
    margin-left: 0.5em;
  }
}

.share-sign-in-promo {
  width: $share-menu-width;
  text-align: center;
  color: rgb(128, 128, 128);
  line-height: 18px;
  padding: 15px 40px;
  border-bottom: 1px solid rgb(224, 224, 224);
  margin-bottom: 0.5em;

  a {
    color: inherit;
  }
}
