.whats-new-dialog {
  width: 500px;
  height: 70vh;
  max-height: 800px;
}

.whats-new-dialog iframe {
  position: absolute; /* We need this to make height actually be 100% */
  width: 100%;
  height: 100%;
  border: 0;
}
