@import "../../styles/variables.scss";

.street-width.street-width-editable {
  cursor: pointer;

  &:hover,
  &:hover * {
    color: darken($ui-colour, 40%);
  }

  &::after {
    font-family: "Verdana", sans-serif;
    content: " ▼";
    padding-left: 5px;
    font-size: 9px;
    display: inline-block;
    transform: scaleY(0.75);
  }
}

.street-width-over {
  margin-left: 0.25em;
  color: $warning-colour;

  [dir="rtl"] & {
    margin-right: 0.25em;
    margin-left: 0;
  }
}

.street-width-under {
  margin-left: 0.25em;
  color: rgb(96, 96, 96);
  transition: color $environment-transition;

  [dir="rtl"] & {
    margin-right: 0.25em;
    margin-left: 0;
  }
}

// Invert the UI text color when there is a dark background
body.dark-environs-invert-ui {
  .street-width-under {
    color: #a0a0a0;
  }
}
