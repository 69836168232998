@import "../../styles/variables.scss";

.street-section-sky {
  position: absolute;
  left: 0;
  right: 0;
  top: -70px; // Space for scrolling down when gallery open
  pointer-events: none;
  opacity: 1;
}

// Used on #error
.clouds-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  // Position of clouds is % based because it needs to be aligned with
  // viewport, not attached to the horizon line
  .rear-clouds {
    bottom: calc(50% - 50px);
  }

  .front-clouds {
    bottom: calc(50% - 250px);
  }
}

.rear-clouds,
.front-clouds,
.mobycon-plane {
  position: absolute;
  left: 0;
  width: 100%;
  background-repeat: repeat-x;
  background-position: 0 0;
  transition: opacity $environment-transition;
}

.rear-clouds {
  height: 250px;
  bottom: 205px;
  background-image: url("../../../public/images/sky-rear.svg");
  background-size: 250px 250px;
}

.front-clouds {
  height: 280px;
  bottom: 55px;
  background-image: url("../../../public/images/sky-front.svg");
  background-size: 250px 280px;
}

.mobycon-plane {
  height: 120px;
  bottom: 250px;
  background-image: url("../../../public/images/mobycon-plane.svg");
  background-repeat: no-repeat;
  background-size: 2050px 133px;
  animation: plane 6s ease-in-out infinite;
}

// A setting activates this class and turns on environmental animations
// This is so it can be disabled based on preference or performance reasons
.environment-animations {
  .rear-clouds {
    animation-name: rear-clouds-move;
    animation-duration: 60s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  .front-clouds {
    animation-name: front-clouds-move;
    animation-duration: 30s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
}

@keyframes rear-clouds-move {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 250px 0;
  }
}

@keyframes front-clouds-move {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 250px 0;
  }
}

@keyframes plane {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-15px);
  }
  100% {
    transform: translatey(0px);
  }
}

.sky-foreground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity $environment-transition;
  z-index: $z-01-foreground;
  pointer-events: none;
  opacity: 0;
}
